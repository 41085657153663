<template>
	<div>

		<!-- Progressbar -->
		<div class="shadow w-full bg-grey-light rounded-lg">
			<div
				v-bind:style="'width:' + progress + '%'"
				class="bg-primary-600 text-xs leading-none py-1 rounded-l-lg text-center text-white"
			>
				{{ progress }}%
			</div>
		</div>

		<!-- Вопрос -->
		<p class="text-lg font-semibold text-gray-700 mt-6 mb-2 text-left">Вопрос {{ number }}</p>
		<p class="text-md mb-4 text-left">{{ question.question }}</p>

		<!-- Ответы -->
		<TestAnswerItem
			v-for="answer in question.answers"
			:key="answer.id"
			:id="answer.id"
			:answer="answer.text"
			@trigger="triggerAnswer"
			:isDisabled="showingAnswer"
			:isMultiAnswer="question.type === 'many-answers'"
		/>

		<!-- Комментарии -->
		<p v-if="noAnswerError" class="pb-3 font-semibold text-primary-600">Выберите ответ!</p>

		<p v-if="showAnswers && showingAnswer">
			<span v-if="isRight" class="pb-3 font-semibold text-green-600">
				Правильно!
			</span>
			<span v-else class="pb-3 font-semibold text-red-700">
				Неверно! Правильный ответ: {{ question.type === 'many-answers' ? 
					question.answers.filter(answer => answer.isCorrect).map(answer => answer.text).join(', ') : 
					question.answers.find(answer => answer.isCorrect).text }}
			</span>
		</p>

		<!-- Кнопка -->
		<button
			@click="answer()"
			class="w-full block m-auto py-1 mt-2 mb-6 rounded-md text-gray-200 bg-primary-600 border-primary-600 font-semibold
				border-2 hover:text-white hover:bg-primary-500 hover:border-primary-500 sm:w-1/3 sm:mr-0 sm:ml-auto"
		>
			<span v-if="!showingAnswer">Ответить</span>
			<span v-else>Продолжить</span>
		</button>

	</div>
</template>

<script>
import TestAnswerItem from '@/components/tests/TestAnswerItem.vue'

const getDefaultState = () => {
	return {
		currentAnswer: null,	// Выбранный вариант ответа (id) или массив при множественном
		noAnswerError: false, // Показывать ошибку при отсутствии выбранного ответа
		showingAnswer: false, // Показывать результат при ответе
		isRight: null,				// Результат текущего ответа
		points: 0							// Очки за текущий ответ
	}
}

export default {
	name: 'TestQuestion',
	components: { TestAnswerItem },
	props: {
		progress: {
			type: Number,
			required: true
		},
		number: {
			type: Number,
			required: true
		},
		question: {
			type: Object,
			required: true
		},
		showAnswers: {
			type: Boolean,
			required: true
		}
	},
	data: () => getDefaultState(),
	methods: {
		triggerAnswer(id) {
			if (this.question.type === 'many-answers') {
				if (this.currentAnswer === null) this.currentAnswer = []
				const index = this.currentAnswer.indexOf(id)
				if (index != -1) {
					this.currentAnswer.splice(index, 1)
				} else {
					this.currentAnswer.push(id)
				}
			} else {
				this.currentAnswer = id
			}
		},
		checkAnswer() {
			if (this.question.type === 'single-answer') {
				if (this.question.answers.find(answer => answer.id == this.currentAnswer).isCorrect) {
					this.points = 1
					this.isRight = true
				}
			} else {
				let result = true

				this.question.answers.forEach((item, index, array) => {
					if (this.currentAnswer.indexOf(item.id) != -1)
						result = result && (this.question.answers.find(answer => answer.id == item.id).isCorrect)
					else
						result = result && !(this.question.answers.find(answer => answer.id == item.id).isCorrect)
				})

				if (result) {
					this.points = 1
					this.isRight = true
				}
			}
		},
		answer() {
			if (this.currentAnswer === null) {
				this.noAnswerError = true
			} else {
				this.noAnswerError = false
				
				// Ответил, показать комментарий
				if (this.showAnswers && !this.showingAnswer) {
					this.checkAnswer()
					this.showingAnswer = true
				} else {
					// Ответил (или ответил и показали комментарий) и переходим дальше
					this.checkAnswer()
					this.$emit('done', this.points)
				}
			}
		}
	},
	watch: {
		// При изменении вопроса сбрасываем данные предыдущего
		question: function() { 
			Object.assign(this.$data, getDefaultState()) 
		}
	}
}
</script>