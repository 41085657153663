<template>
  <div v-if="isUserActive" class=" m-auto pr-2 md:pr-4 lg:pr-8 py-8 vld-parent">

    <loading
      :active="isLoading"
      :is-full-page="false"
      :loader="'bars'"
      :color="'#00afd3'"
      :height="90"
      :width="90"
      :opacity="0"
    />

    <h2 class="text-2xl font-medium text-gray-800 mx-auto text-left">Возник вопрос? Задайте его эксперту!</h2>
    <textarea
      v-model="$v.question.$model"
      class="resize-none w-full h-40 mt-4 py-2 px-3 min-h-30 border border-gray-300 placeholder-gray-500 text-gray-700 
        rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
      placeholder="Задайте вопрос эксперту"
    >      
    </textarea>
    <div
      @click="sendClick()"
      class="cursor-pointer block mr-2 mt-3 rounded-md py-2 font-semibold border-2 text-gray-200 
        hover:text-white bg-primary-600 border-primary-600  hover:bg-primary-400 hover:border-primary-400 md:w-1/2 lg:w-1/3 mr-0 ml-auto"
    >
      Отправить вопрос эксперту
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import SolidButton from '@/components/_partials/SolidButton.vue'

export default {
  mixins: [validationMixin],
  name: 'SendQ',
  props: {
    contentId: {
      type: Number,
      required: true
    }
  },
  components: {
    SolidButton,
    Loading
  },
  data: function() {
    return {
      question: null,
      isLoading: false
    }
  },
  validations: {
    question: {
      required,
      minLength: minLength(20)
    }
  },
  computed: {
    ...mapGetters({
      isUserActive: 'auth/isUserActive'
    }),
    contentType() {
      if (this.$route.name == 'Video') return 'video'
      if (this.$route.name == 'Test') return 'test'
      return null
    }
  },
  methods: {
    ...mapActions({
      sendQuestion: 'sendQuestion'
    }),
    sendClick() {
      if (!this.$v.$invalid) {
        this.isLoading = true
        this.sendQuestion({ contentType: this.contentType, question: this.question, contentId: this.contentId })
          .then(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>

<style>
</style>
