<template>
	<div>
		<p class="text-md mb-5 text-left">{{ description }}</p>
		<p class="text-md mx-auto text-secondary font-semibold mb-5 text-left">Эксперт: {{ expert }}</p>
		<label class="flex justify-start items-start mb-3">
			<div class="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2
				focus-within:border-blue-500"
			>
				<input v-model="showAnswers" type="checkbox" class="opacity-0 absolute">
				<svg class="fill-current hidden w-4 h-4 text-primary-500 pointer-events-none" viewBox="0 0 20 20">
					<path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
				</svg>
			</div>
			<div class="select-none">
				<span>Показывать правильные ответы</span>
			</div>
		</label>
	
		<button
			@click="$emit('start', showAnswers)"
			class="w-full block m-auto py-1 mt-5 mb-4 rounded-md text-gray-200 bg-primary-600 border-primary-600 font-semibold
				border-2 hover:text-white hover:bg-primary-500 hover:border-primary-500 sm:w-1/3 sm:mr-0 sm:ml-auto"
		>
			Начать тест
		</button>
	</div>
</template>

<script>
export default {
	name: 'TestStart',
	props: {
		description: {
			type: String,
			required: true
		},
		expert: {
			type: String,
			required: true
		}
	},
	data: function() {
		return {
			showAnswers: false
		}
	}
}
</script>