<template>
  <div class="px-4">

    <!-- Начальная часть -->
    <TestStart
      v-if="!started"
      :description="test.description"
      :expert="test.expert"
      @start="start"
    />

    <!-- Часть с вопросами -->
    <TestQuestion
      v-if="started && (currentQuestion < questionsTotal)"
      :progress="progress"
      :number="currentQuestion + 1"
      :question="test.data[currentQuestion]"
      :showAnswers="showAnswers"
      @done="next"
    />
    
    <!-- Часть с результатами -->
    <div v-if="currentQuestion == questionsTotal">
      <div class="shadow w-full bg-primary-600 text-white rounded-lg">100%</div>      
      <p class="text-lg font-semibold text-gray-700 mt-6 mb-2 text-center pb-5">
        Тест пройден!<br>Ваш результат {{ pointsGot }} из {{ questionsTotal }} баллов
      </p>

      <!-- Кнопки -->
      <div class="flex justify-center mt-2 mb-6">
        <router-link
          :to="{ name: 'Tests' }"
          class="w-full py-1 mr-1 rounded-md text-gray-200 bg-primary-500 border-primary-500 font-semibold border-2
          hover:text-white hover:bg-primary-400 hover:border-primary-400 sm:w-1/3"
        >
          Вернуться к списку тестов
        </router-link>
        <div
          @click="restart()"
          class="w-full py-1 ml-1 rounded-md text-gray-200 bg-primary-500 border-primary-500 font-semibold border-2
            hover:text-white hover:bg-primary-400 hover:border-primary-400 sm:w-1/3 align-middle cursor-pointer"
        >
          Начать заново
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TestStart from '@/components/tests/TestStart.vue'
import TestQuestion from '@/components/tests/TestQuestion.vue'

const getDefaultState = () => {
  return {
    started: false,           // Тест начался
    showAnswers: false,       // Показывать ответы
    currentQuestion: 0,       // Номер текущего вопроса
    pointsGot: 0              // Текущие набранные баллы
  }
}

export default {
  name: 'Test',
  components: {
    TestStart,
    TestQuestion
  },
  data: () => getDefaultState(),
  methods: {
    ...mapActions({
      sendTestStats: 'tests/sendStats'
    }),
    start(showAnswers) {
      this.started = true
      this.showAnswers = showAnswers
    },
    next(points) {
      this.currentQuestion++
      this.pointsGot += points
      if (this.currentQuestion == this.questionsTotal)
        this.sendTestStats({
          test_id: parseInt(this.$route.params.test_id, 10),
          pointsDone: this.pointsGot,
          pointsTotal: this.questionsTotal
        })
    },
    restart() {
      Object.assign(this.$data, getDefaultState())
    }
  },
  computed: {
    ...mapState({
      test: state => state.tests.currentTest
    }),
    progress() {
      return Math.round(100.0 / this.test.data.length * (this.currentQuestion))
    },
    questionsTotal() {
      return this.test.data.length;
    }
  }
}
</script>

<style>
  input:checked + svg {
  	display: block;
  }
</style>
