<template>
	<div class="block mr-4 mb-4">
    <input
      v-bind:id="(isMultiAnswer ? 'checkbox' : 'radio') + id"
      :type="isMultiAnswer ? 'checkbox' : 'radio'"
      :name="isMultiAnswer ? 'checkbox' : 'radio'"
      class="hidden"
      :value="id"
      :disabled="isDisabled"
      v-model="currentAnswer"
    />
    <label v-bind:for="(isMultiAnswer ? 'checkbox' : 'radio') + id" class="cursor-pointer flex">
      <div class="w-1/8">
        <span class="block h-6 w-6 rounded-full border border-gray-500"></span>
      </div>
      <p class="w-7/8 pl-4 text-left">{{ answer }}</p>
    </label>
  </div>
</template>

<script>
export default {
	name: 'TestAnswerItem',
	props: {
    id: {
			type: Number,
			required: true
		},
    answer: {
			type: String,
			required: true
		},
		isDisabled: {
			type: Boolean,
			required: true
		},
		isMultiAnswer: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		currentAnswer: {
			get: function() {
        return this.value
      },
      set: function() {
        this.$emit('trigger', this.id)
      }
		}
	}
}
</script>

<style>
	input[type="radio"] + label span {
    transition: background .2s, transform .2s;
  }

  input[type="radio"]:checked + label span {
    background-color: #00afd3;
    box-shadow: 0px 0px 0px 4px white inset;
  }

  input[type="radio"]:checked + label {
    color: #00afd3;
	}
	
	input[type="checkbox"] + label span {
    transition: background .2s, transform .2s;
  }

  input[type="checkbox"]:checked + label span {
    background-color: #00afd3;
    box-shadow: 0px 0px 0px 4px white inset;
  }

  input[type="checkbox"]:checked + label {
    color: #00afd3;
  }
</style>