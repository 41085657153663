<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Content -->
    <div v-if="!isLoading" key="content">
      <div class="pr-2 md:pr-4 lg:pr-8">
        <div class="bg-white shadow-md pt-4 pb-2 px-4 rounded-lg">
          <Test />
        </div>
      </div>

      <SendQ :contentId="test.id" />

      <div v-if="test.videos.length">
        <h2 
          class="text-2xl pb-8 font-medium text-gray-800 mx-auto text-left"
          :class="[{'pt-5': !isUserActive}]"
        >Ассоциированные материалы</h2>
        <VideoCard
          v-for="video in test.videos"
          :key="video.id"
          :title="video.name"
          :id="video.id"
          :description="video.description"
          :expert="video.expert"
          :link="{ name: 'Video', params: { video_id: video.id } }"
          :isSaved='video.isFavourite'
          :isAvailable='true'
          :type="'single'"
        />
      </div>
    </div>
  </fade-transition>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'

import VideoCard from '../../components/videos/VideoCard.vue'
import PublicationCard from '@/components/publications/PublicationCard.vue'
import SendQ from '../../components/SendQ.vue'
import Test from '../../components/tests/Test.vue'

export default {
  name: 'TestPage',
  components: {
    FadeTransition,
    LoadingScreen,
    Test,
    VideoCard,
    PublicationCard,
    SendQ
  },
  data: function() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      test: state => state.tests.currentTest
    }),
    ...mapGetters({
      isUserActive: 'auth/isUserActive'
    }),
  },
  methods: {
    ...mapActions({
      getTest: 'tests/getTest',
      resetTest: 'tests/resetTest',
      setContentBreadcrumbs: 'setContentBreadcrumbs'
    })
  },
  created: function() {
    this.getTest(this.$route.params.test_id)
      .then(theme_id => {
        if (theme_id) this.setContentBreadcrumbs(theme_id)
        this.isLoading = false
      })
      .catch(error => {
        if (error.response.status == 403) this.$xmodal.open()
        this.$router.replace({ name: 'Tests' })
      })
  },
  beforeDestroy: function() {
    this.resetTest()
  }
}
</script>
